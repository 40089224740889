import moment from 'moment'
import qs from 'query-string'
import trans from 'counterpart'
import { INTERNAL_DATE } from '../constants/dates'
import {
  PREFERENCE_SUBJECTS,
  PREFERENCE_ARRIVAL,
  PREFERENCE_DEPARTURE,
  PREFERENCE_KINDS,
  PREFERENCE_LOCALE,
  PREFERENCE_PROPERTIES,
  PREFERENCE_RESORTS,
  PREFERENCE_DIRECTION,
  PREFERENCE_SORT
} from '../constants/preferences'
import { getLocaleFromSlug } from './localeUtilities'
import { getInitialArrivalDay } from './dateUtilities'
import { ID_HOLIDAYPARK, ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL } from '../modules/booking/constants'

/**
 * The default preferences.
 * This is also used as the initial state of the `preferencesReducer`.
 */
export const defaults = {
  [PREFERENCE_ARRIVAL]: getInitialArrivalDay(),
  [PREFERENCE_DEPARTURE]: null,
  display: 'grid', // TODO: Move to BookingSearchComponent itself
  [PREFERENCE_KINDS]: [],
  [PREFERENCE_LOCALE]: 'nl',
  [PREFERENCE_PROPERTIES]: [],
  [PREFERENCE_RESORTS]: [ID_HOLIDAYPARK, ID_SAFARIRESORT, ID_SAFARIRESORT_HOTEL],
  // Subjects are mapped as `[ID]: QUANTITY`
  [PREFERENCE_SUBJECTS]: {
    10: 2,
    7: 2
  },
  [PREFERENCE_DIRECTION]: 'desc',
  [PREFERENCE_SORT]: 'popularity'
}

/**
 * Updates the session storage with the preferences that should be stored.
 * Only a few preferences are kept in session storage, to avoid conflicts with non-matching preferences
 *
 * @param {object} req
 * @param {object} preferences
 */
export const updateSessionPreferences = (req, preferences) => {
  req.session.preferences = {
    [PREFERENCE_ARRIVAL]: preferences.arrival,
    [PREFERENCE_SUBJECTS]: preferences.subjects
  }
}

/**
 * This transforms the query params to the correct preference format.
 * @param {object} params
 */
export const transformQueryParams = (params) => {
  const preferences = { ...params }

  if (preferences.resorts) preferences.resorts = preferences.resorts.map((id) => parseInt(id, 10))
  if (preferences.kinds) preferences.kinds = preferences.kinds.map((id) => parseInt(id, 10))

  if (preferences.properties && !Array.isArray(preferences.properties)) {
    preferences.properties = [preferences.properties]
  }

  if (preferences.subjects) {
    preferences.subjects = transformQuerySubjects(preferences.subjects)
  }

  return preferences
}

export const transformQuerySubjects = (subjects = []) => {
  let transformedSubjects = subjects

  if (typeof transformedSubjects === 'string' && !transformedSubjects.includes('?subjects')) {
    // subjects are coming as "9-2", should be stored as { 9: 2 }
    const [subjectId, quantity] = transformedSubjects.split('-')
    transformedSubjects = { [subjectId]: parseInt(quantity, 10) }
  } else if (!Array.isArray(transformedSubjects)) {
    // This is here for backwards compatibility, where subjects might be passed as ?subjects=9:2
    const [subjectId, quantity] = transformedSubjects.split(':')
    transformedSubjects = [
      {
        [subjectId]: parseInt(quantity, 10)
      }
    ]
  } else {
    // Preferences are passed as ?subjects[]=9-2, but should be stored as { 9: 2 }.
    transformedSubjects = transformedSubjects.reduce((subjects, subject) => {
      let split
      if (subject.includes(':')) {
        split = subject.split(':')
      } else {
        split = subject.split('-')
      }

      const count = parseInt(split[1], 10)
      if (!count) return subjects

      return {
        ...subjects,
        [split[0]]: count
      }
    }, {})
  }

  return transformedSubjects
}

/**
 * This merges three levels of preferences onto a single preferences object.
 * It merges the defaults, the session preferences and the query preferences.
 *
 * @param {object} req
 */
export const getInitialPreferences = (req) => {
  const preferences = {
    ...defaults,
    ...req.session.preferences,
    ...transformQueryParams(req.query),
    locale: getLocaleFromSlug(req.url)
  }

  // If the arrival date is in the past, replace it with today's date
  if (preferences.arrival && moment(preferences.arrival) < moment()) {
    preferences.arrival = moment().format(INTERNAL_DATE)
  }

  updateSessionPreferences(req, preferences)

  return preferences
}

export const getBookingUrl = (offer, subjects, properties, additionalProps = {}) =>
  `${trans('routes.booking_url')}?${qs.stringify(
    {
      resort: offer.resort_id,
      resource: offer.resource_id,
      rate_type_id: offer.rate_type_id,
      arrival: offer.arrival_date,
      duration: offer.nights,
      special: offer.special_code || '',
      price: offer.all_in_price,
      properties,
      subjects: Object.keys(subjects || {}).map((subject) => `${subject}:${subjects[subject]}`),
      ...additionalProps
    },
    { skipEmptyString: true }
  )}`

export const handleFocusOnMount = (focus, preferences) => {
  if (preferences.kinds.length > 0) {
    focus.kinds = preferences.kinds
  }
  if (preferences.subjects) {
    focus.subjects = preferences.subjects
  }

  if (preferences.resorts) {
    focus.resorts = preferences.resorts
  }

}
