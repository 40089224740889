const nl = {
  locale: "nl",
  /**
   *  ==================================================================
   *  Contentful
   *  ==================================================================
   */

  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Pagina niet gevonden | Beeksebergen",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title":
    "De mooiste tijd beleef je bij Beeksebergen, onderdeel van Libéma",

  /**
   *  ==================================================================
   *  Old CMS
   *  ==================================================================
   */
  action: {
    create_customer_error: "Het persoonsgegevens formulier bevat nog fouten",
    submit_subjects_error: "Min. 1 volwassene",
  },
  alert: {
    brochure_request_error:
      "Er is een fout opgetreden tijdens je aanvraag. Probeer het later nog eens. Onze excuses voor het ongemak.",
    brochure_request_successful:
      "Je brochure aanvraag is successvol ontvangen. Je ontvangt de brochure binnen enkele dagen per post.",
    brochure_form_error:
      "Er is een fout opgetreden tijdens je aanvraag. Alle velden met een * zijn verplicht.",
    low_availability_for_resource:
      "Let op! Nog enkele accommodaties beschikbaar. Wacht niet te lang met boeken.",
    no_price_found: "Geen prijs gevonden",
    noResults: "Geen resultaten gevonden",
    error: "Er is een fout opgetreden. Probeer het later opnieuw.",
    successful: "Bedankt! Wij hebben het formulier ontvangen.",
    newsletter_subscription_successful:
      "Bedankt voor je aanmelding. We hebben je een e-mail gestuurd. Klik op de link in deze e-mail om jouw aanmelding te bevestigen.",
    newsletter_subscription_unsuccessful:
      "Oeps, er is iets misgegaan. Probeer het op een later moment nog eens.",
    newsletter_subscription_conflict:
      "Oeps, je bent al aangemeld. Mocht je de nieuwsbrief niet ontvangen, bekijk de spambox dan eens. Zie je hem ook hier niet? Neem dan contact met ons op.",
  },
  booking: {
    section_additions: "Bij te boeken extra's",
    section_customer: "Persoonsgegevens",
    section_your_stay: "Je verblijf",
  },
  button: {
    add: "Voeg toe",
    additions: "Ga naar bij te boeken extra's",
    close: "Sluiten",
    customer: "Ga naar persoonsgegevens",
    edit: "Wijzigen",
    filter: "Filter",
    load_more: "Laad meer",
    more_info: "Meer info",
    remove: "Verwijderen",
    reservation_new: "Nieuwe reservering",
    results: "Resultaten",
    reservation_confirmation_and_pay: "Bevestigen en direct betalen",
    reservation_confirmation_and_pay_less_than_6_weeks: "Ga verder",
    reservation_confirmation_and_pay_more_than_6_weeks:
      "Bevestigen en naar betalen",
    voucher_clear: "Voucher verwijderen",
    show_all: "Toon alles",
    sold_out: "Uitverkocht",
    discover: "ontdek",
    submit: "Verzenden",
    complete: "Afronden",
  },
  calendar: {
    legenda: "Legenda",
    selected_day: "Gekozen dag",
    today: "Vandaag",
    opening_hours_park: "Openingstijden Safaripark",
    opening_hours_play: "Openingstijden Speelland",
    closed: "Gesloten",
    open_from: "Open van",
    open_till: "tot",
  },
  chat: {
    connect: "Start chat",
    email: "E-mailadres",
    lastname: "Achternaam",
    name: "Naam",
    question: "Vraag",
  },
  error: {
    api: {
      accommodation_not_available:
        "Deze boeking is niet meer beschikbaar of niet mogelijk met jouw instellingen.",
      arrivaldate_invalid: "De gekozen aankomstdatum is niet geldig.",
      period_invalid: "De boekingsperiode is niet geldig.",
      unexpected:
        "Deze boeking is niet meer beschikbaar of niet mogelijk met jouw instellingen.",
      voucher_not_found:
        "Deze code is helaas niet geldig. Controleer je code en probeer het opnieuw.",
      voucher_not_valid_period:
        "Deze code is helaas niet geldig voor de opgegeven periode",
      voucher_blocked: "Deze code is helaas geblokkeerd",
      voucher_already_redeemed: "Deze code is helaas al gebruikt.",
      voucher_not_valid_accommodation:
        "Deze code is helaas niet geldig voor de opgegeven accommodatie",
      voucher_no_offers: "Deze code bevat geen aanbiedingen",
      voucher_required: "Deze boeking verwacht een voucher code",
    },
    form: {
      preference_not_available:
        "Dit nummer is helaas niet meer beschikbaar. Selecteer een andere.",
    },
    email_invalid: "Ongeldig E-mailadres",
    help: "Hulp nodig? Neem telefonisch contact met ons op via 088-9000360.",
    newyse: {
      general:
        "Er is een fout opgetreden tijdens het ophalen van de beschikbaarheid. Probeer het later nog eens of neem contact op via 088-9000360",
      not_available: "Dit verblijf is niet beschikbaar in de gekozen periode",
    },
    save_booking_failure:
      "Er is iets fout gegaan bij het opsturen van de e-mail.",
    unknown_payment_error:
      "Er is een onbekende fout opgetreden bij het betalen voor de reservering.",
    unknown_reservation_error:
      "Er is een onbekende fout opgetreden bij het opvragen van de reservering",
    no_reservation_criteria_error:
      "Er is een fout opgetreden, er lijken reserveringsgegevens te missen. Probeer het later nog eens.",
    internal: {
      title: "Oeps... Er is iets misgegaan!",
      message:
        "Er is helaas iets misgegaan. Probeer het later nog eens of neem contact op met ons Contactcenter.<br /><br /> Voor vragen over een verblijf bij het Lake Resort, het Safari Resort of het Safari Hotel kan je telefonisch contact opnemen via <strong>088-9000360</strong> of per e-mail via: <strong>Info@libemafunfactory.nl</strong>. Heb je vragen over entree tot het Safaripark? Bel dan naar <strong>088-9000321</strong> of mail naar <strong>gastenservicesafaripark@beeksebergen.nl</strong>.",
      email: "info@libemafunfactory.nl",
      phone: "088-9000360",
      phoneVal: "0889000360",
    },
  },
  footer: {
    require_booking_help_header: "Hulp nodig bij het boeken?",
    require_booking_help_body:
      "Onze medewerkers staan elke dag telefonisch voor je klaar via ",
    require_booking_help_days:
      "(maandag t/m vrijdag van 08.30 - 18.00 uur en zaterdag en zondag van 09.00 - 17.00 uur)",
    require_booking_help_appendix: ".",
    pay_online_terms_header: "Betaal online of in termijnen",
    pay_online_terms_body:
      "Na het bevestigen van je reservering heb je de keuze om je reservering direct online te betalen, in zijn geheel of in twee termijnen.",
    travel_documents_header: "Reisbescheiden",
    travel_documents_body:
      "Na je reservering ontvang je een mail met de voorlopige bevestiging. Na controle door onze medewerkers ontvang je de definitieve bevestiging van je reservering.",
  },
  form: {
    datefield_invalid: "Geboortedatum is ongeldig",
    field_mandatory: "Dit veld is verplicht",
    field_email_invalid: "E-mailadres is ongeldig",
    field_email_2_error: "De e-mailadressen komen niet overeen",
    fields_required: "Deze velden zijn verplicht",
    placeholder: {
      search: "Zoeken",
      birth_date: "dd-mm-jjjj",
    },
    termsConditions: {
      text1: "Ik ga akkoord met de ",
      text2: "algemene voorwaarden",
      link2: "https://www.beeksebergen.nl/algemene-voorwaarden-overnachten",
      text3: ", de ",
      text4: "Recron-voorwaarden",
      recronUrl: {
        dierenbos:
          "https://dierenbos.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        lake_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_hotel:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
      },
      text5: " en het ",
      text6: "parkreglement",
      text7: " van het ",
      text8:
        " Exploitatie B.V. behoudt zich het recht voor om deze voorwaarden en het parkreglement te wijzigen.",
      linkdierenbos: "https://www.dierenbos.nl/algemene-voorwaarden",
      parkreglementUrl: {
        safari_resort:
          "https://www.beeksebergen.nl/parkreglement/safari-resort-beekse-bergen",
        lake_resort:
          "https://www.beeksebergen.nl/parkreglement/lake-resort-beekse-bergen",
        safari_hotel:
          "https://www.beeksebergen.nl/parkreglement/safari-hotel-beekse-bergen",
        dierenbos:
          "https://d214t2x9zeu1qk.cloudfront.net/originals/parkreglement_vakantiepark_dierenbos_2022.pdf",
      },
    },
  },
  label: {
    all: "Alles",
    from: "Van",
    from_price: "vanaf %(price)s",
    for: "Voor",
    at: "bij",
    by: "door",
    discount: "Korting",
    total: "Totaal",
    edit: "Wijzigen",
    holidays: "vakanties",
    holiday: "vakantie",
    no: "Nee",
    persons: "Personen",
    refine_selection: "Selectie verfijnen",
    preferences: "Voorkeuren",
    read_more: "Lees meer",
    select_stay: "Selecteer een overnachting...",
    travel_company: "Reisgezelschap",
    travel_company_edit: "Reisgezelschap wijzigen",
    loading_holidays: "Vakanties zoeken",
    select_date: "Selecteer een datum",
    share: "Deel deze reservering met anderen",
    specifications: "Specificaties",
    yes: "Ja",
    your_booking: "Jouw boeking",
    voucher: "Voucher",
    per_person: "p.p.",
    default_payment_choices:
      "Na het verzenden van je reservering heb je de keuze om je reservering direct online te betalen, in zijn geheel of in twee termijnen.",
    // shortarrival_payment_choices: "Let op: door op 'Bevestigen en direct betalen' te klikken ga je een betalingsverplichting aan. Omdat je vakantie binnen %(weeks)s weken plaats zal vinden, dien je na het bevestigen het volledige bedrag direct online te voldoen.",
    shortarrival_payment_choices:
      "Let op: door op ‘Ga verder’ te klikken ga je een betalingsverplichting aan. Omdat je vakantie binnen %(weeks)s weken plaats zal vinden, dien je na het bevestigen het volledige bedrag direct online te voldoen.",
    mediumarrival_payment_choices:
      "Na het verzenden van je reservering kan je jouw reservering direct online te betalen.",
    longarrival_payment_choices:
      "Na het verzenden van je reservering heb je de keuze om je reservering direct online te betalen, in zijn geheel of in twee termijnen.",
    tickets: "Kaarten",
    accommodation_type_content: "Pagina",
    all_lastminutes: "Bekijk alle last minutes",
    available: "beschikbaar",
    on: "op",
    period_from: "%period% vanaf",
    future_booking: "Maak alvast een voorreservering voor 2026",
    future_booking_dierenbos: "Maak alvast een voorreservering voor 2026",
    future_booking_reminder:
      "Ontvang een e-mail zodra het mogelijk is om voor 2026 te boeken",
    future_booking_reminder_dierenbos:
      "Ontvang een e-mail zodra het mogelijk is om voor 2026 te boeken",
    future_reservation: "Voorreservering",
    book_reminder: "Ontvang herinnering",
    show_as: "Bekijk als",
    title: "Titel",
    inclusive_stay: "Inclusief bij je verblijf",
    "# title": "Aanhef",
    mister: "Dhr.",
    miss: "Mevr.",
    family: "Familie",
    first_name: "Voornaam",
    middle_name: "Tussenvoegsel",
    last_name: "Achternaam",
    address: "Adres",
    housenumber: "Huisnummer",
    housenumber_suffix: "Toevoeging",
    zipcode: "Postcode",
    city: "Plaatsnaam",
    country: "Land",
    email: "E-mail adres",
    phone: "Telefoon",
    email_optin:
      "Ja, ik wil op de hoogte gehouden worden middels email. U ontvangt niet vaker dan eens in de twee weken een nieuwsbrief met o.a. aanbiedingen en win-acties.",
    brochure: "Brochure",
    help: "Help",
    new: "Nieuw",
    choose_map: "Plek op plattegrond kiezen",
    compare: "Vergelijken",
    compare_add: "+ toevoegen aan vergelijking",
    week_later: "Week later",
    week_earlier: "Week eerder",
    add: "Toevoegen",
    book: "boeken",
    direct: "Direct",
    not_available_in_period:
      "Dit verblijf is niet beschikbaar in de gekozen periode",
    safari_resort_opening: "Het Safari Resort opent vanaf april 2018",
    nights: "Nachten",
    night: "nacht",
    days: "Dagen",
    book_now: "Direct boeken",
    read_less: "Lees minder",
    more: "meer",
    compare_more: "Meer vergelijken",
    add_accommodation: "Accommodatie toevoegen",
    choose_accommodation_to_compare:
      "Kies de accommodaties die je wil vergelijken",
    choose_quantity: "Kies aantal",
    arrival: "Aankomst",
    arrival_date: "Aankomstdatum",
    departure: "Vertrek",
    departure_date: "Vertrekdatum",
    discover_more: "Ontdek meer",
    search_book: "Zoek en boek",
    today: "Vandaag",
    tomorrow: "Morgen",
    closed: "Gesloten",
    compare_type: "Vergelijk met een ander type",
    photos: "foto's",
    description: "omschrijving",
    facilities: "faciliteiten",
    select_accommodation: "Selecteer een accommodatie type",
    search: "Zoeken",
    full: "vol",
    view: "bekijk",
    filters_active: "filters actief",
    is_winner: "Is een winnaar?",
    newsletter: "Inschrijven voor nieuwsbrief?",
    terms: "Voorwaarden geaccepteerd?",
    image: "Afbeelding",
    preferences_text: "Voorkeuren tekst",
    competition: "Wedstrijd",
    reservation_payment_cancel:
      "U staat op het punt uw reservering te annuleren. Weet u dit zeker? U kunt uw reservering ook op een later moment afronden",
    save_booking_body:
      "Vul een e-mailadres in en ontvang een e-mail met een link naar deze reservering.",
    save_booking_button: "Stuur mij de link",
    save_booking_success:
      "Bedankt, de e-mail met de link sturen we direct naar je toe.",
    save_booking_title: "Liever later afronden of delen?",
    special: "Bijzonderheden",
    submit_date: "Datum inzending",
    datepicker_toggle_hotel_camping:
      "Flexibele wisseldagen (kampeerplaatsen, Rooms & Suites)",
    datepicker_toggle_arrivals:
      "Vaste wisseldagen (vakantiewoningen, glamping tenten)",
    date_info_dialog_link: "Meer informatie over aankomst en vertrekdagen.",
    date_info_dialog_title: "Aankomst- en vertrekdagen",
    date_info_dialog_text1:
      "Wij hanteren verschillende aankomst- en vertrekdagen per park en per accommodatietype.",
    date_info_dialog_text2: "Vakantiewoningen en glamping tenten",
    date_info_dialog_text3:
      "Voor onze vakantiewoningen en glamping tenten gelden vaste aankomst- en vertrekdagen, alsmede een minimale en maximale verblijfsduur.",
    date_info_dialog_list1_item1_key: "Aankomst:",
    date_info_dialog_list1_item1_value: "Maandag, woensdag, vrijdag",
    date_info_dialog_list1_item2_key: "Minimale verblijfsduur:",
    date_info_dialog_list1_item2_value:
      "2 nachten voor midweekverblijf, 3 nachten voor weekendverblijf",
    date_info_dialog_list1_item3_key: "Maximale verblijfsduur:",
    date_info_dialog_list1_item3_value: "21 nachten*",
    date_info_dialog_text4: "Rooms & Suites",
    date_info_dialog_text5:
      "Voor onze Rooms & Suites worden flexibele aankomst en vertrekdagen gehanteerd.",
    date_info_dialog_list2_item1_key: "Aankomst:",
    date_info_dialog_list2_item1_value:
      "Alle dagen van de week, m.u.v. bijzondere periodes (hoogseizoen, feestdagen en muziekevenementen)",
    date_info_dialog_list2_item2_key: "Minimale verblijfsduur:",
    date_info_dialog_list2_item2_value:
      "Alleen van toepassing op zaterdag (2 nachten) en tijdens bijzondere periodes (feestdagen en festivals)",
    date_info_dialog_list2_item3_key: "Maximale verblijfsduur:",
    date_info_dialog_list2_item3_value: "14 nachten*",
    date_info_dialog_text6: "Kampeerplaatsen",
    date_info_dialog_text7:
      "Kampeerplaatsen zijn seizoensgebonden en zijn alleen tussen april en oktober beschikbaar. Binnen het kampeerseizoen kunnen kampeerplaatsen op alle dagen geboekt worden.",
    date_info_dialog_list3_item1_key: "Aankomst:",
    date_info_dialog_list3_item1_value:
      "Alle dagen van de week, m.u.v. bijzondere periodes (hoogseizoen, feestdagen en muziekevenementen)",
    date_info_dialog_list3_item2_key: "Minimale verblijfsduur:",
    date_info_dialog_list3_item2_value:
      "Niet van toepassing, m.u.v. bijzondere periodes (feestdagen en festivals)",
    date_info_dialog_list3_item3_key: "Maximale verblijfsduur:",
    date_info_dialog_list3_item3_value: "21 nachten*",
    date_info_dialog_text8:
      "* Wil je langer dan bovenstaande maximale verblijfsduur verblijven op één van onze parken? Neem dan contact op met ons Contact Center via +31889000360.",
    date_info_dialog_terms_conditions_part1: "Bekijk hier de ",
    date_info_dialog_terms_conditions_part2: "annuleringsvoorwaarden",
    date_info_dialog_terms_conditions_part3: ".",
  },
  nav: {
    faq: "Veelgestelde vragen",
    help: "Hulp nodig? Bel ons via 088-9000360",
    opening_hours:
      "(ma-vrij tot 18.00 uur, za tot 17.00 uur, zo tot 16.00 uur)",
  },
  newyse: {
    reservation_unavailable: "Deze reservering is helaas niet meer beschikbaar",
    accommodation: {
      almost_soldout: "Nog enkele beschikbaar",
      availability_resort:
        "Wist je dat op Safari Resort ook woensdag mogelijk is als aankomstdag?",
      book: "Direct boeken",
      booking_offline_warning:
        "Aangezien de aankomst vandaag is, is alleen telefonisch reserveren mogelijk op 088 - 9000 360.",
      more_info: "Meer info",
      resort_unavailable: "Het Safari Resort opent vanaf april 2018",
      unavailable: "Niet beschikbaar.",
      unavailable2: "eerst mogelijke aankomstdatum",
      check: "Bekijk",
      via_more_info: "via meer info.",
      no_results: "Er is geen accommodatie type geselecteerd voor dit blok.",
    },
    compare: {
      duplicate: "Deze accommodatie is reeds geselecteerd",
      label: "Vergelijk",
      max: "U heeft het maximale aantal accommodaties om te vergelijken bereikt",
      start: "Start met vergelijken",
    },
    no_results: "Geen resultaten gevonden. Pas a.u.b. uw zoekcriteria aan.",
    resort: {
      holidaypark: "Lake Resort Beekse Bergen",
      resort: "Safari Resort Beekse Bergen",
    },
  },
  personal_information: {
    title: "Aanhef",
    book_company: "Boeken als bedrijf",
    main_booker: "Naam hoofdboeker",
    birth_date: "Geboortedatum",
    phone_number: "Telefoonnummer",
    phone_number_muted:
      "Vul bij voorkeur een mobiel telefoonnummer in bestaande uit 10 cijfers.",
    email: "E-mailadres",
    confirm_email: "Bevestig e-mailadres",
    confirmation_info:
      "Je ontvangt de bevestiging van jouw boeking op dit e-mailadres.",
    newsletter_prefix: "Ik ontvang graag de ",
    newsletter: "Beekse Bergen Nieuwsbrief",
    newsletter_suffix: "",
    zipcode: "Postcode",
    address: "Straat",
    city: "Woonplaats",
    country: "Land",
    comments: "Opmerkingen",
    agree: "Ik ga akkoord met ",
    deposit: "de borg procedure voorwaarden",
    deposit_href:
      "https://beeksebergen.s3-eu-west-1.amazonaws.com/static/borgbrief-beekse-bergen-2018-2019.pdf",
    park_regulations: "het parkreglement",
    park_regulations_href:
      "https://beeksebergen.s3-eu-west-1.amazonaws.com/static/parkreglement-beekse-bergen-2018-2019.pdf",
    terms_and_conditions: "de Algemene Voorwaarden",
    terms_and_conditions_href:
      "https://beeksebergen.s3-eu-west-1.amazonaws.com/originals/algemene-voorwaarden-overnachten-beekse-bergen-2017-2019.pdf",
    terms_and_conditions_info:
      "(Indien je boekt bij het Safari Resort sluit je een overeenkomst met Safari Resort Exploitatie B.V. en ga je akkoord met de bijgevoegde algemene voorwaarden en het parkreglement van Beekse Bergen Exploitatie B.V., waarbij Safari Resort Exploitatie B.V. als gebruiker heeft te gelden. Safari Resort Exploitatie B.V. behoudt zich het recht voor om deze voorwaarden en het parkreglement te wijzigen.)",
    after_confirmation: "",
    mr: "Dhr",
    mrs: "Mvr",
    placeholder_first_name: "Voornaam",
    placeholder_middle_name: "Tussenv.",
    placeholder_last_name: "Achternaam",
    placeholder_date_format: "dd-mm-jjjj",
    placeholder_phone_number: "0612345678",
    placeholder_house_number: "Huisnr",
    placeholder_addition: "Toev.",
    pay: "Reservering verzenden",
  },
  placeholder: {
    address_and_number: "Straatnaam en huisnummer",
    address: "Straatnaam",
    city: "Plaatsnaam",
    country: "Land",
    email: "Emailadres",
    first_name: "Voornaam of voorletters",
    housenumber_suffix: "Bijvoorbeeld 2H of Apt 3.",
    housenumber: "Huisnummer",
    initials: "Voorletters",
    last_name: "Achternaam",
    middle_name: "Tussenvoegsel",
    phone: "Inclusief land code, bijv. +316123456789",
    street: "Straatnaam",
    title: "Aanhef",
    zipcode: "Postcode",
    voucher: "Vul hier jauw vouchercode in",
  },
  preferences: {
    label: "Voorkeuren",
    no_preference: "Selecteer",
    select_object: "Kies hier jouw specifieke voorkeur",
    costs: "22,50 (direct aan de leeuwenvlakte is eenmalig € 45,00)",
    location:
      "Heb je een voorkeur voor het thema of de ligging van jouw accommodatie/kampeerplaats? Maak je keuze hieronder en betaal eenmalig slechts",
    object_selected: "Je hebt %(object)s geselecteerd",
    show_map: "Toon de plattegrond van het %(resort)s",
  },
  pricing: {
    additional_fees: "Bijkomende kosten",
    all_in_help: "All-in prijs uitleg",
    discount: "Uw korting",
    payment: {
      label: "Nu",
      pay: "betalen",
    },
    total: "Totaalprijs",
    total_amount: "Totaal bedrag",
    total_person: "Totaal p.p.",
    pay_total: "Voldoe in één keer",
    pay_half: "Betaal nu 50%% en de rest van het bedrag na ontvangst van de definitieve bevestiging.",
    pay_partially:
      "Betaal nu 30%% en de rest van het bedrag na ontvangst van de definitieve bevestiging:",
  },
  receipt: {
    access_all: "Toegang Safaripark, Speelland, Dierenrijk en Zooparc",
    access_speelland: "Toegang Speelland",
    cleaning: "Eindschoonmaak",
    inclusive: "Inclusief",
    linen: "Bedlinnen + opgemaakte bedden",
    reservation_costs: "Reserveringskosten",
    towels: "Handdoekenpakket",
    breakfast_buffet: "Ontbijtbuffet",
  },
  refine: {
    park: "Park",
    accommodation: "Accommodaties",
    camping: "Kamperen",
    empty_filters: "Filters leeghalen",
    apply: "Toepassen",
    modal: {
      title: "Let op",
      chosen: "Je hebt gekozen voor de voorkeur",
      pets_allowed: "huisdieren toegestaan",
      no_pets:
        "Je hebt op dit moment geen huisdier opgenomen in je reisgezelschap.",
      add_pets:
        "Als je van plan bent een huisdier mee te nemen vragen we je om dit toe te voegen aan je reisgezelschap.",
    },
    view: "Bekijk",
  },
  resort: {
    speelland: "Speelland",
    safari_resort: "Safari Resort",
    safari_hotel: "Safari Hotel",
    lake_resort: "Lake Resort",
    dierenbos: "Vakantiepark Dierenbos",
    bb: "Beekse Bergen",
    ec: "Event Center",
    r: "Resort",
    sl: "Speelland",
    sp: "Safaripark",
    vp_bb: "Beekse Bergen",
  },
  routes: {
    accommodations: "overnachten/accommodaties/",
    accommodations_dierenbos: "vakantiehuizen/",
    booking_url: "/boeking",
    booking_url_fallback: "/boeking-storing",
    compare: "/overnachten/accommodaties/vergelijk",
    compare_dierenbos: "/vergelijken",
    home: "/",
    payment_request: "/boeking/betaling",
    reserve: "/overnachten/reserveren-2026",
    reserve_dierenbos: "/reserveren-2026",
    reminder: "/overnachten/reminder-prijzen-2026",
    reminder_dierenbos: "/aanmelden-reminder-prijzen-2026",
    search: "/zoeken",
    search_book: "/overnachten/zoek-en-boek",
    search_book_dierenbos: "/zoek-en-boek",
    tickets: "/tickets",
  },
  share: {
    compare: "Vergelijk meer prijzen",
    label: "Deel deze reservering met anderen",
    mail: {
      body: "Dit kwam ik tegen op de website van Lake Resort Beekse Bergen",
      subject: "Unieke vakantie bij Lake Resort Beekse Bergen.",
    },
    accommodation: "Deze accommodatie delen",
  },
  sort: {
    best_match: "Best passend",
    popularity: "Populariteit",
    price_asc: "Prijs (oplopend)",
    price_desc: "Prijs (aflopend)",
  },
  stay: {
    arrival: "Aankomst",
    days: "dagen",
    departure: "Vertrek",
    check_in: "Inchecken vanaf 15.00 uur",
    check_in_resort: "Inchecken vanaf 16.00 uur",
    check_in_special: "Inchecken vanaf 18.00 uur",
    check_in_camping: "Inchecken vanaf 13.00 uur",
    check_in_hotel: "Inchecken vanaf 15.00 uur",
    check_out: "Uitchecken tot 10.00 uur",
    check_out_camping: "Uitchecken tot 11.00 uur",
    check_out_hotel: "Uitchecken tot 11.00 uur",
    label: "Verblijfsduur",
    night: "nacht",
    nights: "nachten",
    no_results:
      "Er zijn geen resultaten beschikbaar. <br/> Pas uw reisgezelschap aan.",
    including: "Inclusief bij je verblijf",
  },
  search: {
    no_results: "Sorry, we hebben geen resultaten kunnen vinden voor",
    placeholder: "Voer een nieuwe zoekopdracht in",
    try_again: "probeer een nieuwe zoekopdracht",
  },
  status: {
    loading_reservation: "Laden van reservering",
  },
  subscribe: {
    caption: "Ontvang één a twee keer per maand het laatste nieuws!",
    newsletter_bb: "Beekse Bergen Nieuwsbrief",
    placeholder: "Jouw e-mailadres",
    sign_up: "Aanmelden",
    signed_up: "U bent aangemeld",
  },
  title: {
    // available_accommodations: '{0} Geen accommodaties beschikbaar|{1} 1 type accommodatie beschikbaar|[2,Inf] %count% type accommodaties beschikbaar',
    available_accommodations: "type accommodaties beschikbaar",
    available_on_lakeresort: "op Lake Resort Beekse Bergen",
    available_on_resort: "op Safari Resort Beekse Bergen",
    available_at_hotel: "in Safari Hotel Beekse Bergen",
    filters: "Filters",
    payment_method: "Kies je betaalmethode",
    payment_method_subtitle: "Selecteer een van de betaalmethoden.",
    reservation_number: "Reserveringsnummer",
    reservation_title_term_short: "Bijna zover",
    reservation_subtitle_term_short:
      "Om je boeking definitief te maken hoef je alleen nog maar af te rekenen.",
    reservation_title_term_medium: "Bijna zover",
    reservation_subtitle_term_medium:
      "Om je boeking definitief te maken hoef je alleen nog maar af te rekenen.",
    reservation_title_term_long: "Je boeking is succesvol verzonden",
    reservation_subtitle_term_long:
      "Je kunt nu je reservering direct online betalen.",
    voucher_form: "Bekijk jouw persoonlijke aanbieding",
    voucher_form_subtitle_1:
      "Heb je een voucher- of cadeaubon van ons ontvangen?",
    voucher_form_subtitle_2:
      "Vul deze dan hier in om jouw aanbieding te verzilveren.",
  },
  travel_company: {
    heading: "Hoeveel mensen neem je mee? Er moet minimaal 1 volwassene zijn",
    minimum: "Min. 1 volwassene",
  },
  reservation_checklist: {
    prices: "Altijd <strong>all-in prijzen</strong>, geen verrassingen",
    confirmation:
      "Je ontvangt direct een <strong>voorlopige bevestiging</strong>",
    extras:
      "Iets vergeten? Later kun je altijd nog <strong>extra's bijboeken</strong>",
    cancellation: "Gratis annuleren binnen 24 uur na reservering",
    prices_dierenbos: "Altijd all-in prijzen, geen verrassingen",
    size_dierenbos: "2400 m² aan speeltuinen",
    pond_dierenbos: "Natuurlijke zwemvijver",
  },
  cancelReservation: {
    title: "Betaling",
    subtitle:
      "Je boeking zal definitief worden na betaling, maar je staat op het punt om deze reservering af te breken. Wil je hiermee doorgaan?",
    reservationNumber: "Reserveringsnummer",
    cancelPolicy: {
      text1: "Bekijk hier de ",
      text2: "annuleringsvoorwaarden",
      text3: ".",
      link: "/overnachten/annuleringsvoorwaarden",
    },
    actions: {
      continuePayment: "Doorgaan met betalen",
      continuePaymentHelper:
        "Je blijft op de betaalpagina en kan hier de betaling afronden.",
      exitReservation: "Later betalen",
      exitReservationHelper:
        "Je gaat terug naar de home pagina. De boeking blijft bestaan en we sturen je per e-mail instructies om de betaling later af te ronden.",
      cancelReservation: "Terug naar de vorige stap",
      cancelReservationHelper:
        "Je huidige boeking wordt geannuleerd. Je gaat terug naar de vorige stap en kan daar jouw boeking gegevens controleren, wijzigen en opnieuw bevestigen.",
    },
    errors: {
      cancelBookingFailure:
        "Er zijn (tijdelijk) geen boekingen mogelijk met jouw instellingen. Probeer de pagina eerst te verversen. Toch hulp nodig? Neem telefonisch contact met ons op via 088-9000360.",
    },
  },
};

export default nl;
