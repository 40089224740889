const en = {
  locale: "en",
  /**
   *  ==================================================================
   *  Contentful
   *  ==================================================================
   */

  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Page not found | Beeksebergen",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title": "De mooiste tijd beleef je bij Beeksebergen, Part of Libéma",

  /**
   *  ==================================================================
   *  Old CMS
   *  ==================================================================
   */
  action: {
    submit_subjects_error: "Minimum of 1 adult required",
    create_customer_error: "The personal information form has errors",
  },
  alert: {
    noResults: "No results found",
    availabillity_failure:
      "An error occurred while retrieving availability. Please try again later or contact us at +31(0)88-9000360",
    brochure_form_error:
      "Er is een fout opgetreden tijdens uw aanvraag. Alle velden met een * zijn verplicht.",
    brochure_request_error:
      "An error has occurred while processing your request. Please try again later. We apologize for the inconvenience.",
    brochure_request_successful:
      "Uw brochure aanvraag is successvol ontvangen. U ontvangt uw brochure binnen enkele dagen per post.",
    close: "Close",
    error: "An error occurred. Please try again.",
    successful: "Bedankt! Wij hebben het formulier ontvangen.",
    newsletter_subscription_successful:
      "Bedankt voor je aanmelding. We hebben je een e-mail gestuurd. Klik op de link in deze e-mail om jouw aanmelding te bevestigen.",
    newsletter_subscription_unsuccessful:
      "Oeps, er is iets misgegaan. Probeer het op een later moment nog eens.",
    newsletter_subscription_conflict:
      "Oeps, je bent al aangemeld. Mocht je de nieuwsbrief niet ontvangen, bekijk de spambox dan eens. Zie je hem ook hier niet? Neem dan contact met ons op.",
  },
  button: {
    filter: "Filter",
    load_more: "Load more",
    more_info: "More info",
    results: "Results",
    show_all: "Show all",
    request_brochure: "Request brochure",
    submit: "Submit",
    close: "Close",
    view_results: "View results",
    voucher_clear: "Clear voucher",
    add: "Add",
    remove: "Remove",
    edit: "Edit",
    reservation_new: "New reservation",
    reservation_confirmation_and_pay: "Confirm and pay now",
    reservation_confirmation_and_pay_less_than_6_weeks: "Continue",
    reservation_confirmation_and_pay_more_than_6_weeks:
      "Confirm and continue to payment",
    complete: "Complete",
  },
  calendar: {
    legenda: "Legend",
    selected_day: "Selected day",
    today: "Today",
    opening_hours_park: "Opening hours Safaripark",
    opening_hours_play: "Opening hours Speelland",
    closed: "Closed",
    open_from: "Open from",
    open_till: "till",
    opening_hours: "Openings hours",
  },
  form: {
    datefield_invalid: "Date of birth is invalid",
    field_mandatory: "This field is required",
    field_email_invalid: "This email address is invalid",
    field_email_2_error: "The email addresses do not match",
    fields_required: "These fields are required",
    placeholder: {
      search: "Search",
      birth_date: "dd-mm-yyyy",
    },
    required: {
      fields: "These fields are required",
    },
    send: "Send",
    termsConditions: {
      text1: "I agree to the ",
      text2: "Terms and Conditions",
      link2: "https://www.beeksebergen.nl/en/park-regulations/stay-the-night",
      text3: ", the ",
      text4: "Recron Terms and Conditions",
      recronUrl: {
        dierenbos:
          "https://dierenbos.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        lake_resort:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
        safari_hotel:
          "https://beeksebergen.s3.eu-west-1.amazonaws.com/originals/948--recron-voorwaarden-vakantieverblijven--%%2528groen%%2529---nederlands-%%25284%%2529.pdf",
      },
      text5: " and the ",
      text6: "park regulations",
      text7: " of ",
      text8:
        " Exploitatie B.V. reserves the right to change these terms & conditions and the park regulations.",
      parkreglementUrl: {
        safari_resort:
          "https://www.beeksebergen.nl/en/park-regulations/safari-resort-beekse-bergen",
        lake_resort:
          "https://www.beeksebergen.nl/en/park-regulations/lake-resort-beekse-bergen",
        safari_hotel:
          "https://www.beeksebergen.nl/en/park-regulations/safari-hotel-beekse-bergen",
      },
    },
  },
  label: {
    all: "All",
    at: "at",
    by: "by",
    default_payment_choices:
      "After confirming your reservation, you’ll be given the option to pay your reservation online directly, in full or in two instalments.",
    edit: "Edit",
    from_price: "from %(price)s",
    no: "No",
    persons: "Persons",
    preferences: "Preferences",
    read_more: "Read more",
    travel_company: "Travelling party",
    travel_company_edit: "Change travelling party",
    select_date: "Select a date",
    share: "Share this booking with others",
    specifications: "Specifications",
    yes: "Yes",
    voucher: "Voucher",
    your_booking: "Your booking",
    accommodation_type_content: "Page",
    add: "Add",
    add_accommodation: "Add accommodation",
    address: "Addresa",
    all_in_device_price_explanation: "All in price mobile explanation",
    all_in_price_explanation: "All in price explanation",
    all_lastminutes: "View all last minutes",
    arrival: "Arrival",
    arrival_date: "Arrival date",
    available: "available",
    book: "book",
    book_now: "Book now",
    brochure: "Brochure",
    chat_block: "Remote URL",
    choose_accommodation_to_compare:
      "Choose the accommodations you want to compare",
    choose_map: "Choose place on map",
    choose_quantity: "Choose quantity",
    city: "City/town",
    closed: "Closed",
    compare: "Compare",
    compare_add: "+ Add to comparison",
    compare_more: "Compare more",
    compare_type: "Compare with another type",
    competition_entry: "Competition Entry",
    country: "Country",
    cta_button_link: "Ticketbar button link",
    cta_button_sub_title: "Ticketbar button sub title",
    cta_button_title: "Ticketbar button title",
    cta_green_button_link: "Ticketbar green button link",
    cta_green_button_sub_title: "Ticketbar green button sub title",
    cta_green_button_title: "Ticketbar green button title",
    days: "Days",
    departure: "Departure",
    departure_date: "Departure date",
    description: "description",
    direct: "Direct",
    discover_more: "Discover more",
    email: "E-mail address",
    email_optin:
      "Yes, I would like to receive the Beekse Bergen Newsletter (please note, the newsletter is in Dutch)",
    enable_green_button_title: "Enable green button",
    execute_neywse_call: "Execute Newyse call",
    extra_person_rate_info: "Extra person rate info",
    facilities: "facilities",
    family: "Family",
    filters_active: "active filters",
    first_name: "First name",
    full: "full",
    future_booking: "Make a pre-booking for 2026",
    future_booking_reminder:
      "Receive an email as soon as it is possible to make a booking for 2026",
    future_reservation: "Pre-booking",
    book_reminder: "Receive a reminder",
    help: "Help",
    holiday: "holiday",
    housenumber: "House number",
    housenumber_suffix: "Addition",
    image: "Image",
    inclusive_stay: "Your stay includes",
    is_winner: "Is a winner?",
    last_name: "Last name",
    loading_holidays: "Loading holidays",
    middle_name: "Middle name",
    miss: "Mrs",
    mister: "Mr.",
    more: "more",
    new: "New",
    newsletter: "Subscribe to newsletter?",
    nights: "Nights",
    not_available_in_period:
      "This accommodation is not available during the chosen period",
    on: "on",
    padding_mobile: "Padding mobile",
    period_from: "%period% from",
    phone: "Phone number",
    photos: "photos",
    preferences_text: "Preferences text",
    read_less: "Read less",
    refine_selection: "Refine selection",
    reservation_payment_cancel:
      "You are about to cancel your reservation. Are you sure you want to confirm this cancellation? You can also complete your reservation at a later time.",
    safari_resort_opening: "The Safari Resort opens from april 2018",
    search_book: "Search and book",
    select_accommodation: "Select an accommodation type",
    // shortarrival_payment_choices: 'Note: by clicking on ‘Confirm and pay now’, you will commit to making a payment. Because your holiday is scheduled to take place within %(weeks)s weeks, you must immediately pay the full amount online once you have given your confirmation.',
    shortarrival_payment_choices:
      "Please note: by clicking on ‘Continue’, you will commit to making a payment. Because your holiday is scheduled to take place within %(weeks)s weeks, you must immediately pay the full amount online once you have given your confirmation.",
    mediumarrival_payment_choices:
      "After confirming your reservation, you can pay your reservation online directly.",
    longarrival_payment_choices:
      "After confirming your reservation, you’ll be given the option to pay your reservation online directly, in full or in two instalments.",
    show_as: "Show as",
    subjects_in_base_price: "Subjects in base price",
    terms: "Agreed to terms of participation.",
    ticketbar_sub_title: "Ticketbar subtitle",
    ticketbar_title: "Ticketbar title",
    tickets: "Tickets",
    title: "Title",
    today: "Today",
    tomorrow: "Tomorrow",
    view: "view",
    week_earlier: "Week earlier",
    week_later: "Week later",
    save_booking_body:
      "Fill in your email address, and you will receive a link to finalise your reservation later.",
    save_booking_button: "Send me the link",
    save_booking_success:
      "Thank you, the email with the link will be sent to you right away.",
    save_booking_title: "Would you rather complete it at a later time?",
    special: "n/a",
    zipcode: "Post code",
    datepicker_toggle_hotel_camping:
      "Flexible changeover days (camping pitches, Rooms & Suites)",
    datepicker_toggle_arrivals:
      "Fixed changeover days (holiday homes, glamping tents)",
    date_info_dialog_link: "More information about arrival and departure days.",
    date_info_dialog_title: "Arrival and departure days",
    date_info_dialog_text1:
      "We have different arrival and departure days for each park and for each type of accommodation.",
    date_info_dialog_text2: "Holiday accommodations and glamping tents",
    date_info_dialog_text3:
      "Our holiday accommodations and glamping tents are subject to set arrival and departure days, as well as a minimum and maximum length of stay.",
    date_info_dialog_list1_item1_key: "Arrival days:",
    date_info_dialog_list1_item1_value: "Monday, Wednesday, Friday",
    date_info_dialog_list1_item2_key: "Minimum length of stay:",
    date_info_dialog_list1_item2_value:
      "2 nights for a mid-week stay, 3 nights for a weekend stay",
    date_info_dialog_list1_item3_key: "Maximum length of stay:",
    date_info_dialog_list1_item3_value: "21 nights*",
    date_info_dialog_text4: "Rooms & Suites",
    date_info_dialog_text5:
      "Our Rooms & Suites are subject to flexible arrival and departure days.",
    date_info_dialog_list2_item1_key: "Arrival days:",
    date_info_dialog_list2_item1_value:
      "Every day of the week, except for special periods (high season, public holidays and music events)",
    date_info_dialog_list2_item2_key: "Minimum length of stay:",
    date_info_dialog_list2_item2_value:
      "Only applicable on Saturday (2 nights) and during special periods (public holidays and festivals)",
    date_info_dialog_list2_item3_key: "Maximum length of stay:",
    date_info_dialog_list2_item3_value: "14 nights*",
    date_info_dialog_text6: "Camping pitches",
    date_info_dialog_text7:
      "The camping pitches are seasonal and only available between April and October. Camping pitches can be booked on any day of the week during the camping season.",
    date_info_dialog_list3_item1_key: "Arrival days:",
    date_info_dialog_list3_item1_value:
      "Every day of the week, except for special periods (high season, public holidays and music events)",
    date_info_dialog_list3_item2_key: "Minimum length of stay:",
    date_info_dialog_list3_item2_value:
      "Not applicable, expect during special periods (public holidays and festivals)",
    date_info_dialog_list3_item3_key: "Maximum length of stay:",
    date_info_dialog_list3_item3_value: "21 nights*",
    date_info_dialog_text8:
      "* If you would like to stay at one of our parks for longer than the maximum length of stay, please call the Contact Centre on +31889000360.",
    date_info_dialog_terms_conditions_part1: "View the ",
    date_info_dialog_terms_conditions_part2: "cancellation policy",
    date_info_dialog_terms_conditions_part3: " here.",
  },
  nav: {
    faq: "Frequently asked questions",
    help: "Need help booking? Call us on 088-9000360",
    opening_hours: "(mon-fri until 18:00, sa until 17:00, sun until 16:00)",
  },
  preferences: {
    costs: "22,50 (right on the lion plain is a one-time fee of €45.00)",
    label: "Preferences",
    location:
      "Do you have a preference when it comes to your accommodations/camping pitch, theme or location? Make your choice below and pay a one-time fee of only",
    no_preference: "Select",
    object_selected: "You selected %(object)s",
    select_object: "Choose your specific preference here",
    show_map: "Show the map of the %(resort)s",
  },
  pricing: {
    additional_fees: "Additional costs",
    all_in_help: "All-in price explanation",
    discount: "Your discount",
    payment: {
      label: "Now",
      pay: "pay",
    },
    total: "Total price",
    total_amount: "Total amount",
    total_person: "Total p.p.",
    pay_total: "Pay in one go:",
    pay_half: "Pay 50%% now and the rest of the amount after receiving the final confirmation.",
    pay_partially:
      "Pay 30%% now and the rest of the amount after receiving the final confirmation:",
  },
  receipt: {
    inclusive: "Included",
    breakfast_buffet: "Breakfast buffet",
  },
  resort: {
    safari_resort: "Safari Resort",
    safari_hotel: "Safari Hotel",
    lake_resort: "Lake Resort",
    dierenbos: "Vakantiepark Dierenbos",
    bb: "Beekse Bergen",
    ec: "Event Center",
    r: "Resort",
    sl: "Speelland",
    sp: "Safari Park",
    vp_bb: "Beekse Bergen",
  },
  share: {
    label: "Share this booking with others",
    accommodation: "Share this accommodation",
    compare: "Compare more prices",
    mail: {
      body: "I've found something you might like too",
      subject: "See what I encountered on the website of Beekse Bergen!",
    },
  },
  stay: {
    arrival: "Arrival",
    days: "Days",
    departure: "Departure",
    check_in: "Check-in from 15:00",
    check_in_resort: "Check-in from 16:00",
    check_in_camping: "Check-in from 13:00",
    check_in_hotel: "Check-in from 3:00 PM",
    check_out: "Check out until 10:00",
    check_out_camping: "Check out until 11:00",
    check_out_hotel: "Check-out before 11:00 AM",
    label: "Duration of stay",
    nights: "nights",
    night: "night",
    access_sl: "Access to Speelland",
    access_sp_sl: "Access to Safaripark and Speelland",
    attraction_pass: "FunCard",
    basic_rent: "Basic rental rate",
    bedding: "Bed linen and made beds",
    book_now: "Book now",
    camp: {
      basic_rent: "Basic rental rate incl. 2 persons",
    },
    cleaning: "Final cleaning",
    included: "What is included",
    including: "Your stay includes",
    local_charges: "Local charges",
    package: {
      towel: "Hand towel set",
      welcome: "Welcome package",
    },
    reservation_fee: "Reservation fee",
  },
  status: {
    loading_reservation: "Loading reservation",
  },
  title: {
    // available_accommodations: '{0} No accommodations available|{1} 1 type accommodation available|[2,Inf] %count% type accommodations available',
    available_accommodations: "type accommodations available",
    available_on_lakeresort: "on Lake Resort",
    available_on_resort: "on Safari Resort Beekse Bergen",
    available_at_hotel: "on Safari Hotel Beekse Bergen",
    filters: "Filters",
    errors: "Error overview",
    lastminutes: "Lastminutes",
    notification: "Notification",
    notification_results: "Notification results",
    notifications: "Notifications",
    payment_method: "Choose your payment method",
    payment_method_subtitle: "Select one of the payment methods.",
    reservation_number: "Reservation number",
    reservation_title_term_short: "Almost there",
    reservation_subtitle_term_short:
      "To finalize your booking, all you have to do is pay.",
    reservation_title_term_medium: "Almost there",
    reservation_subtitle_term_medium:
      "To finalize your booking, all you have to do is pay.",
    reservation_title_term_long: "Your booking has been sent successfully",
    reservation_subtitle_term_long:
      "You can now pay for your reservation directly online.",
    voucher_form: "View your personal offer",
    voucher_form_subtitle_1:
      "Have you received a voucher or gift code from us?",
    voucher_form_subtitle_2: "Enter it here to redeem your offer.",
  },
  accommodation: {
    no_results: "There is no accommodation type selected.",
  },
  brochure: {
    address: "Straat en Huisnummer",
    brochure: "Ik wil een brochure aanvragen van",
    city: "Plaats",
    country: "Land",
    email: "E-mailadres",
    housenumber: "Huisnummer",
    initials: "Voorletters",
    last_name: "Achternaam",
    middle_name: "Tussenvoegsel",
    phone: "Telefoonnummer",
    street: "Straatnaam",
    title: "Aanhef",
    zipcode: "Postcode",
  },
  error: {
    api: {
      accommodation_not_available:
        "This booking is no longer available, or not possible with your preferences. Please check verify your selection.",
      arrivaldate_invalid: "The arrivaldate is invalid.",
      period_invalid: "The bookingperiod is invalid.",
      unexpected:
        "This booking is no longer available, or not possible with your preferences.",
      voucher_required: "This booking requires a voucher code",
    },
    form: {
      preference_not_available:
        "This number is no longer available. Please select another one.",
    },
    customer_service: "This reservation is not available",
    email_invalid: "Invalid e-mail address",
    help: "Need help? Contact us by phone at 088-9000360.",
    newyse: {
      general:
        "An error occurred while retrieving availability. Please try again later or contact us at +31(0)88-9000360",
    },
    save_booking_failure: "The email failed to send.",
    unknown_payment_error:
      "An unknown error occurred while paying for the reservation.",
    internal: {
      title: "Oops ... Something went wrong!",
      message:
        "Unfortunately something went wrong. Please try again later or contact our Contact Centre.<br /><br />For questions about a stay at our Lake Resort, Safari Resort or Safari hotel, please contact us by telephone at <strong>+31 (0) 88-9000360</strong> or by e-mail at: <strong>Info@libemafunfactory.nl</strong>. Do you have any questions about admission to the Safari Park? Phone <strong>+31 (0) 88-9000321</strong> or e-mail <strong>gastenservicesafaripark@beeksebergen.nl</strong>.",
      email: "info@libemafunfactory.nl",
      phone: "+31 (0)88-9000360",
      phoneVal: "+310889000360",
    },
  },
  gallery: {
    images: "photos",
  },
  help: {
    chat_block: "The remote url for the KPN Storm chat",
    jumbotron_videoimage:
      "This image will be loaded when the video is not available",
    notification_email_addresses:
      "In case you would like to send an email as notification enter e-mail addresses separated by ;",
    selligent_help: "The selligent ID",
    send_empty_result:
      "Should the notification be send in case of an empty result",
    subjects_in_base_price:
      "The amount of subjects included in the base price shown as 'huursom 'number' personen'",
  },
  kind: {
    camping: "Camping",
  },
  modals: {
    error: {
      meet_requirements:
        "The travelling party does not meet the requirement of the reservation. Please change the travelling party.",
      reservation_unavailable: "Your reservation is not available",
      try_again:
        "An error occurred while making the reservation. Please try again later or contact us at +31(0)88-9000360",
    },
    info: {
      travel_company: {
        body: "Because no pets are allowed in this accommodation, we have removed it from your travel company.",
        title: "Travelling party is adjusted",
      },
    },
  },
  newyse: {
    accommodation: {
      almost_soldout: "Only a few available",
      availability_resort:
        "Did you know that on Safari Resort, Wednesday is possible as an arrival day?",
      book: "Book now",
      booking_offline_warning:
        "Since the arrival date is today, reservations can only be made by phone at +31 (0)88 - 9000 360.",
      check: "View",
      more_info: "More info",
      no_results: "There is no accommodation type selected.",
      resort_unavailable: "The Safari Resort opens from april 2018",
      unavailable2: "first available arrival date.",
      unavailable: "Not available.",
      via_more_info: "via more info.",
    },
    compare: {
      duplicate: "This accommodation has already been selected",
      label: "Compare",
      max: "You have reached the maximum number of accommodations to compare",
      start: "Start to compare",
    },
    no_results: "No results found. Please adjust your search criteria.",
    last_minute_special: "Last minute",
    reservation_unavailable:
      "This reservation is unfortunately not available anymore",
    resort: {
      holidaypark: "Lake Resort Beekse Bergen",
      resort: "Safari Resort Beekse Bergen",
    },
    stay: {
      days: "{1}1 day|[2,Inf]%count% days",
      nights: "{1}1 night|[2,Inf]%count% nights",
    },
  },
  placeholder: {
    address: "Address",
    address_and_number: "Street and housenumber",
    city: "City/town",
    country: "Country",
    email: "E-mail address",
    first_name: "First name or initials",
    housenumber: "Housenumber",
    housenumber_suffix: "For example 2H or Apt 3.",
    initials: "Initials",
    last_name: "Last name",
    middle_name: "Middle name",
    phone: "Including country code, eg. +316123456789",
    street: "Street",
    title: "Title",
    zipcode: "Post code",
    voucher: "Enter your vouchercode here",
  },
  refine: {
    accommodation: "Accommodations",
    apply: "Apply",
    camping: "Camping",
    close: "Close",
    empty_filters: "Empty filters",
    modal: {
      add_pets:
        "If you are going to take a pet with, please add this to your travel company.",
      chosen: "You chose preference",
      no_pets: "You haven't selected a pet as your travelling company.",
      pets_allowed: "pets allowed",
      title: "Notice",
    },
    park: "Park",
    result: "result",
    results: "results",
    stay: "Stay",
    view: "View",
  },
  routes: {
    anchor: {
      photos: "photos",
      description: "description",
      facilities: "facilities",
    },
    accommodations: "stay-the-night/accommodations/",
    booking_url: "/en/booking",
    booking_url_fallback: "/en/booking-malfunction-form",
    compare: "/en/stay-the-night/accommodations/compare",
    lake_resort: "/lake-resort/",
    home: "/en",
    last_minute: "/en/stay-the-night/special-offers/last-minute-deals",
    newsletter: "/nieuwsbrief",
    payment_request: "/en/booking/payment",
    reminder: "/en/stay-the-night/reminder-prices-2026",
    reserve: "/en/stay-the-night/reservations-2026",
    search: "/en/search",
    search_book: "/en/stay-the-night/search-and-book",
    tickets: "/en/tickets",
  },
  search: {
    no_results: "Sorry, we could not find any results for",
    placeholder: "Search",
    try_again: "try a new search",
  },
  sort: {
    best_match: "Best match",
    popularity: "Popularity",
    price_asc: "Price (ascending)",
    price_desc: "Price (descending)",
  },
  subscribe: {
    caption: "Note: You are signing up for the Dutch newsletter",
    newsletter_bb: "Beekse Bergen Newsletter",
    placeholder: "Your E-mail address",
    sign_up: "Sign up",
  },
  ticket: {
    label_sp: "",
    last_minute: "Last-minutes",
    last_minute_discount: "Up to 30% discount",
    online_discount: "Online discount",
    order: "Order tickets",
    receive: "I would like to receive the",
    subtitle: "Exploring our beautiful nature will not cost the earth.",
    title: "Fancy a day out or a longer stay?",
  },
  travel_company: {
    adult: "Adult",
    baby: "Baby aged 3 and under",
    child: "Child aged 12 and under",
    heading: "How many people do you bring? There should be at least 1 adult",
    pet: "Pet",
    senior: "Senior 55+",
    minimum: "Minimum of 1 adult required",
  },
  weather: {
    closed: "Closed",
    today: "Today",
    tomorrow: "Tomorrow",
  },
  reservation_checklist: {
    prices: "Always <strong> all-in prices </strong>, no surprises",
    confirmation:
      "You will immediately receive a <strong> preliminary confirmation </strong>",
    extras:
      "Forget something? Later you can always book <strong> extras </strong>",
    cancellation: "Free cancellation within 24 hours of booking",
    prices_dierenbos: "Always <strong> all-in prices </strong>, no surprises",
    size_dierenbos: "2400 m² of playgrounds",
    pond_dierenbos: "Natural swimming pond",
  },
  cancelReservation: {
    title: "Payment",
    subtitle:
      "Your booking will be definitive after payment, but you are about to cancel this reservation. Do you want to continue with this?",
    reservationNumber: "Reservation number",
    cancelPolicy: {
      text1: "View the ",
      text2: "cancellation conditions",
      text3: " here.",
    },
    actions: {
      continuePayment: "Continue with payment",
      continuePaymentHelper:
        "You will remain on the payment page and can complete the payment here.",
      exitReservation: "Pay later",
      exitReservationHelper:
        "You will return to the homepage. The booking will remain valid and we will send you instructions by email in order to complete the payment later.",
      cancelReservation: "Return to the previous step",
      cancelReservationHelper:
        "Your current booking will be cancelled. You will return to the previous step and you can check, change and reconfirm your booking details there.",
    },
    errors: {
      cancelBookingFailure:
        "No bookings are possible (temporarily) with your settings. Try to refresh the page first. Still need help? Please contact us on +31 (0)88-9000360.",
    },
  },
};

export default en;
