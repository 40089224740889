const de = {
  locale: "de",
  /**
   *  ==================================================================
   *  Contentful
   *  ==================================================================
   */

  /**
   *  ==================================================================
   *  404 Page
   *  ==================================================================
   */
  "page.404.title": "Seite nicht gefunden | Beeksebergen",

  /**
   *  ==================================================================
   *  Footer
   *  ==================================================================
   */
  "footer.title":
    "Die beste Zeit erleben Sie in Beeksebergen, einem Teil von Libéma",

  /**
   *  ==================================================================
   *  OLD CMS
   *  ==================================================================
   */
  action: {
    submit_subjects_error: "mindestens 1 Erwachsener",
    create_customer_error: "Die persönliche Form enthält Fehler",
  },
  accommodation: {
    no_results: "Es gibt keine Unterkunftstyp ausgewählt.",
  },
  alert: {
    noResults: "Keine Ergebnisse gefunden",
    brochure_request_error:
      "Fehler während der Verarbeitung Ihrer Anfrage. Bitte versuchen Sie es später erneut. Wir entschuldigen uns für die Unannehmlichkeiten.",
    brochure_request_successful:
      "Uw brochure aanvraag is successvol ontvangen. U ontvangt uw brochure binnen enkele dagen per post.",
    brochure_form_error:
      "Er is een fout opgetreden tijdens uw aanvraag. Alle velden met een * zijn verplicht.",
    availabillity_failure:
      "Fehler beim Abrufen Verfügbarkeit. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns unter + 31(0)88-9000360",
    close: "schließen",
    error:
      "Es ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.",
    successful: "Bedankt! Wij hebben het formulier ontvangen.",
    newsletter_subscription_successful:
      "Bedankt voor je aanmelding. We hebben je een e-mail gestuurd. Klik op de link in deze e-mail om jouw aanmelding te bevestigen.",
    newsletter_subscription_unsuccessful:
      "Oeps, er is iets misgegaan. Probeer het op een later moment nog eens.",
    newsletter_subscription_conflict:
      "Oeps, je bent al aangemeld. Mocht je de nieuwsbrief niet ontvangen, bekijk de spambox dan eens. Zie je hem ook hier niet? Neem dan contact met ons op.",
  },
  brochure: {
    brochure: "Ik wil een brochure aanvragen van",
    initials: "Voorletters",
    middle_name: "Tussenvoegsel",
    last_name: "Achternaam",
    address: "Straat en Huisnummer",
    street: "Straatnaam",
    housenumber: "Huisnummer",
    zipcode: "Postcode",
    city: "Plaats",
    country: "Land",
    email: "E-mailadres",
    phone: "Telefoonnummer",
    title: "Aanhef",
  },
  button: {
    filter: "Filter",
    load_more: "Laden Sie mehr",
    more_info: "Mehr Info",
    results: "Ergebnisse",
    show_all: "Zeige alles",
    request_brochure: "Brochure aanvragen",
    close: "Schließen",
    submit: "Verzenden",
    add: "Hinzufügen",
    remove: "Entfernen",
    edit: "Ändern",
    reservation_new: "Neue reservierung",
    reservation_confirmation_and_pay: "Bestätigen und direkt bezahlen",
    reservation_confirmation_and_pay_less_than_6_weeks: "Weiter",
    reservation_confirmation_and_pay_more_than_6_weeks:
      "Bestätigen und zur Zahlung",
    voucher_clear: "klarer Gutschein",
    complete: "Vollständig",
  },
  calendar: {
    legenda: "Legende",
    selected_day: "Ausgewählten Tag",
    today: "Heute",
    opening_hours_park: "Geschäftszeiten Safaripark",
    opening_hours_play: "Geschäftszeiten Speelland",
    closed: "Geschlossen",
    open_from: "Offen",
    open_till: "zu",
    opening_hours: "Geschäftszeiten",
  },
  form: {
    datefield_invalid: "Geburtsdatum ist ungültig",
    field_email_invalid: "Diese E-Mail-Adresse ist ungültig",
    field_email_2_error: "Die E-mail-Addressen mussen übereinstimmen",
    field_mandatory: "Dieses Feld wird benötigt",
    fields_required: "Diese Felder sind Pflichtfelder",
    placeholder: {
      search: "Suche",
      birth_date: "tt-mm-jjjj",
    },
    required: {
      fields: "Diese Felder sind Pflichtfelder",
    },
    send: "Senden",
    termsConditions: {
      text1: "Ich stimme den ",
      text2: "Allgemeinen Geschäftsbedingungen",
      link2:
        "https://www.beeksebergen.nl/de/park-richtlinien/park-richtlinie-ubernachten",
      text3: ", den ",
      text4: "Recron-Geschäftsbedingungen",
      recronUrl: {
        dierenbos:
          "http://dierenbos.s3.eu-west-1.amazonaws.com/originals/949---recron-voorwaarden-vakantieverblijven-%%2528groen%%2529--duits.pdf",
        safari_resort:
          "http://beeksebergen.s3.eu-west-1.amazonaws.com/originals/949---recron-voorwaarden-vakantieverblijven-%%2528groen%%2529--duits.pdf",
        lake_resort:
          "http://beeksebergen.s3.eu-west-1.amazonaws.com/originals/949---recron-voorwaarden-vakantieverblijven-%%2528groen%%2529--duits.pdf",
        safari_hotel:
          "http://beeksebergen.s3.eu-west-1.amazonaws.com/originals/949---recron-voorwaarden-vakantieverblijven-%%2528groen%%2529--duits.pdf",
      },
      text5: " und der ",
      text6: "Park-Richtlinie",
      text7: " zu ",
      text8:
        " Exploitatie B.V. behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen und die Parkordnung zu ändern.",
      linkdierenbos:
        "https://www.dierenbos.nl/de/allgemeine-geschaftsbedingungen",
      parkreglementUrl: {
        safari_resort:
          "https://www.beeksebergen.nl/de/park-richtlinien/safari-resort-beekse-bergen",
        lake_resort:
          "https://www.beeksebergen.nl/de/park-richtlinien/safari-resort-beekse-bergen",
        safari_hotel:
          "https://www.beeksebergen.nl/de/park-richtlinien/safari-hotel-beekse-bergen",
        dierenbos:
          "http://dierenbos.s3.eu-west-1.amazonaws.com/originals/db_algemene-voorwaarden_2020_du.pdf",
      },
    },
  },
  label: {
    all: "Alle",
    at: "im",
    by: "von",
    compare_add: "Zum Vergleich hinzufügen",
    edit: "Ändern",
    from_price: "ab %(price)s",
    no: "Nein",
    persons: "Personen",
    preferences: "Sonderwunsche",
    read_more: "Mehr lesen",
    travel_company: "Reisegesellschaft",
    travel_company_edit: "Reisegruppe andern",
    select_date: "Wählen Sie ein Datum",
    share: "Diese Buchung mit anderen teilen",
    specifications: "Spezifikationen",
    yes: "Ja",
    voucher: "Coupon",
    your_booking: "Ihre Buchung",
    accommodation_type_content: "Seite",
    all_lastminutes: "Alle Last Minute Angebote",
    available: "verfügbar",
    on: "auf",
    period_from: "%period% vom",
    refine_selection: "Auswahl verfeinern",
    show_as: "Ansicht als",
    title: "Titel",
    inclusive_stay: "Ihr Aufenthalt beinhaltet",
    mister: "Herr",
    miss: "Frau",
    family: "Familie",
    future_booking:
      "Inzwischen können Sie Ihren Aufenthalt für 2026 vorreservieren lassen",
    future_booking_dierenbos:
      "Inzwischen können Sie Ihren Aufenthalt für 2026 vorreservieren lassen",
    future_booking_reminder:
      "Sie erhalten eine E-Mail, sobald eine Buchung für 2026 möglich ist",
    future_booking_reminder_dierenbos:
      "Sie erhalten eine E-Mail, sobald eine Buchung für 2026 möglich ist",
    future_reservation: "Vorreservierung",
    book_reminder: "Erinnerung erhalten",
    first_name: "Vorname",
    middle_name: "Namenszusatz",
    last_name: "Familienname",
    address: "Adresse",
    housenumber: "Hausnummer",
    housenumber_suffix: "Ergänzung",
    zipcode: "Postleitzahl",
    city: "Wohnort",
    country: "Land",
    email: "E-Mail-Adresse",
    phone: "Telefon",
    email_optin:
      "Ja, ich möchte den Newsletter von Beekse Bergen erhalten (in niederländischer Sprache).",
    brochure: "Brochure",
    help: "Hilfe",
    new: "Neu",
    choose_map: "Wählen Sie Platz auf der Karte",
    compare: "Vergleichen",
    week_later: "Woche später",
    week_earlier: "Woche früher",
    add: "Hinzufügen",
    book: "buchen",
    direct: "Direkt",
    not_available_in_period:
      "Diese Unterkunft ist für den gewählten Zeitraum nicht verfügbar",
    safari_resort_opening: "Das Safari Resort öffnet ab April 2018",
    nights: "Nächte",
    night: "Nacht",
    days: "Tage",
    book_now: "Direkt buchen",
    read_less: "Text einklappen",
    reservation_payment_cancel:
      "Sie sind kurz davor, Ihre Buchung zu stornieren. Sind Sie sicher? Sie können Ihre Buchung auch zu einem späteren Zeitpunkt abschließen;",
    more: "mehr",
    compare_more: "Vergleichen Sie mehr",
    add_accommodation: "Unterkunft hinzufügen",
    choose_accommodation_to_compare: "Unterkünfte zum Vergleichen auswählen",
    choose_quantity: "Menge wählen",
    arrival: "Anreise",
    arrival_date: "Tag der Anreise",
    departure: "Abreise",
    departure_date: "Tag der Abreise",
    discover_more: "Mehr dazu",
    tickets: "Tickets",
    search_book: "Suchen und buchen",
    today: "Heute",
    tomorrow: "Morgen",
    closed: "Geschlossen",
    loading_holidays: "Urlaub wird geladen",
    compare_type: "Mit anderer Unterkunftsart vergleichen",
    photos: "fotos",
    description: "beschreibung",
    facilities: "einrichtungen",
    select_accommodation: "Wählen Sie eine Unterkunft",
    full: "voll",
    view: "ansehen",
    filters_active: "Aktive Filter",
    holiday: "Urlaub",
    default_payment_choices:
      "Nachdem die Buchung abgesandt wurde, können Sie diese direkt online bezahlen, entweder komplett oder in zwei Raten.",
    // shortarrival_payment_choices: 'Achtung: Wenn Sie auf „Bestätigen und direkt bezahlen“ klicken, gehen Sie eine Zahlungsverpflichtung ein. Da Ihr Urlaub innerhalb der nächsten %(weeks)s Wochen stattfinden wird, müssen Sie nach der Bestätigung den vollständigen Betrag umgehend online begleichen.',
    shortarrival_payment_choices:
      "Bitte beachten: Wenn Sie auf „Weiter“ klicken, gehen Sie eine Zahlungsverpflichtung ein. Da Ihr Urlaub innerhalb der nächsten %(weeks)s Wochen stattfinden wird, müssen Sie nach der Bestätigung den vollständigen Betrag umgehend online begleichen.",
    mediumarrival_payment_choices:
      "Nachdem die Buchung abgesandt wurde, können Sie diese direkt online bezahlen.",
    longarrival_payment_choices:
      "Nachdem die Buchung abgesandt wurde, können Sie diese direkt online bezahlen, entweder komplett oder in zwei Raten.",
    save_booking_body:
      "Geben Sie Ihre E-Mail-Adresse ein, dann erhalten Sie einen Link, mit dem Sie die Buchung dann abschließen können.",
    save_booking_button: "Bitte Link zusenden",
    save_booking_success:
      "Vielen Dank, wir senden Ihnen sofort eine E-Mail mit dem Link.",
    save_booking_title: "Wollen Sie die Buchung lieber später abschließen?",
    select_stay: "Wählen Sie eine Übernachtung",
    special: "n/a",
    datepicker_toggle_hotel_camping:
      "Flexible Wechseltage (Campingplätze, Zimmer & Suiten)",
    datepicker_toggle_arrivals:
      "Feste Wechseltage (Ferienhäuser, Glampingzelte))",
    date_info_dialog_link: "Mehr Informationen zu An- und Abreisetagen.",
    date_info_dialog_title: "An- und Abreisetage",
    date_info_dialog_text1:
      "Die An- und Abreisetage sind pro Park und pro Unterkunftstyp unterschiedlich.",
    date_info_dialog_text2: "Ferienwohnungen und Glamping-Zelte",
    date_info_dialog_text3:
      "Für unsere Ferienwohnungen und Glamping-Zelte gelten feste An- und Abreisetage sowie eine Mindest- und eine Höchstgrenze für die Aufenthaltsdauer.",
    date_info_dialog_list1_item1_key: "Ankunftstage:",
    date_info_dialog_list1_item1_value: "Montag, Mittwoch, Freitag",
    date_info_dialog_list1_item2_key: "Mindestaufenthaltsdauer:",
    date_info_dialog_list1_item2_value:
      "2 Nächte für einen Aufenthalt in der Woche und 3 Nächte für einen Aufenthalt am Wochenende",
    date_info_dialog_list1_item3_key: "Maximale Aufenthaltsdauer:",
    date_info_dialog_list1_item3_value: "21 Nächte*",
    date_info_dialog_text4: "Hotelzimmer & Suiten",
    date_info_dialog_text5:
      "Für unsere Hotelzimmer & Suiten gelten flexible An- und Abreisetage.",
    date_info_dialog_list2_item1_key: "Ankunftstage:",
    date_info_dialog_list2_item1_value:
      "Jeden Tag der Woche, außer zu besonderen Zeiten (Hochsaison, Feiertage und Musikveranstaltungen)",
    date_info_dialog_list2_item2_key: "Mindestaufenthaltsdauer:",
    date_info_dialog_list2_item2_value:
      "Nur gültig an Samstagen (2 Nächte) und während besonderer Zeiträume (Feiertage und Festivals)",
    date_info_dialog_list2_item3_key: "Maximale Aufenthaltsdauer:",
    date_info_dialog_list2_item3_value: "14 Nächte*",
    date_info_dialog_text6: "Campingstellplätze",
    date_info_dialog_text7:
      "Die Stellplätze sind saisongebunden und nur zwischen April und Oktober verfügbar. In der Campingsaison können die Stellplätze an allen Tagen gebucht werden.",
    date_info_dialog_list3_item1_key: "Ankunftstage:",
    date_info_dialog_list3_item1_value:
      "Jeden Tag der Woche, außer zu besonderen Zeiten (Hochsaison, Feiertage und Musikveranstaltungen)",
    date_info_dialog_list3_item2_key: "Mindestaufenthaltsdauer:",
    date_info_dialog_list3_item2_value:
      "Nicht zutreffend, mit Ausnahme von besonderen Zeiträumen (Feiertage und Festivals)",
    date_info_dialog_list3_item3_key: "Maximale Aufenthaltsdauer:",
    date_info_dialog_list3_item3_value: "21 Nächte*",
    date_info_dialog_text8:
      "* Möchten Sie sich über die maximale Aufenthaltsdauer hinaus in einem unserer Parks aufhalten? Bitte wenden Sie sich dann an unser Contact Center unter +31889000360.",
    date_info_dialog_terms_conditions_part1: "Die ",
    date_info_dialog_terms_conditions_part2: "Stornierungsbedingungen",
    date_info_dialog_terms_conditions_part3: " finden Sie hier.",
  },
  nav: {
    faq: "Haufig gestellte Fragen",
    help: "Sie brauchen Hilfe bei der buchung? Rufen Sie uns an 088-9000360",
    opening_hours: "(Mon-Frei bis 18.00, Sam bis 17.00, Sonn bis 16.00)",
  },
  preferences: {
    costs: "22,50 (Unterkunft direkt an der Löwenfläche ist € 45,00 (einmalig)",
    label: "Sonderwünsche",
    location:
      "Haben Sie bestimmte Wünsche in Bezug auf Thema oder Lage Ihrer Unterkunft/Ihres Camping-Stellplatzes? Wir berücksichtigen Ihren Sonderwunsch zum Preis von nur",
    no_preference: "Wählen",
    object_selected: "Du hast %(object)s ausgewählt",
    select_object: "Wählen Sie hier Ihre spezifische Präferenz",
    show_map: "Zeige die Karte des %(resort)ss",
  },
  pricing: {
    additional_fees: "Zusatzkosten",
    all_in_help: "All-in Preis Erklarung",
    discount: "Ihr Rabatt",
    payment: {
      label: "Jetzt",
      pay: "bezahlen",
    },
    total: "Gesamtpreis",
    total_amount: "Gesamtpreis",
    total_person: "Preis p.P.",
    pay_total: "Auf einmal bezahlen",
    pay_half:
    "Zahlen Sie jetzt 50%% und den Restbetrag nach Erhalt der endgültigen Bestätigung.",
    pay_partially:
      "Zahlen Sie jetzt 30%% und den Restbetrag nach Erhalt der endgültigen Bestätigung:",
  },
  receipt: {
    inclusive: "Inbegriffen",
    breakfast_buffet: "Frühstücksbuffet",
  },
  resort: {
    safari_resort: "Safari Resort",
    safari_hotel: "Safari Hotel",
    lake_resort: "Lake Resort",
    dierenbos: "Vakantiepark Dierenbos",
    bb: "Beekse Bergen",
    ec: "Event Center",
    r: "Resort",
    sl: "Speelland",
    sp: "Safaripark",
    vp_bb: "Beekse Bergen",
  },
  share: {
    label: "Diese Buchung mit anderen teilen",
    mail: {
      body: "Ich habe etwas gefunden, das Ihnen auch gefallen konnte",
      subject:
        "Sehen Sie, was ich auf der Website von Beekse Bergen angetroffen habe!",
    },
    accommodation: "Diese Unterkunft teilen",
    compare: "Vergleichen Sie mehr Preise",
  },
  stay: {
    arrival: "Anreise",
    days: "Tage",
    departure: "Abreise",
    check_in: "Check-in ab 15:00 Uhr",
    check_in_resort: "Check-in ab 16:00 Uhr",
    check_in_special: "Check-in ab 18:00 Uhr",
    check_in_camping: "Check-in ab 13:00 Uhr",
    check_in_hotel: "Check-in ab 15:00 Uhr",
    check_out: "Check out bis 10:00 Uhr",
    check_out_camping: "Check out bis 11:00 Uhr",
    check_out_hotel: "Check-out vor 11:00 Uhr",
    label: "Aufenthaltsdauer",
    night: "Nacht",
    nights: "Nachte",
    access_sp_sl: "Eintritt ins Safaripark und Speelland",
    access_sl: "Eintritt ins Speelland",
    attraction_pass: "FunCard",
    basic_rent: "Grundmiete",
    book_now: "Direkt buchen",
    bedding: "Bettwäsche und Frisch bezogene Betten",
    cleaning: "Endreinigung",
    included: "Was ist inklusive",
    including: "Ihr Aufenthalt beinhaltet",
    local_charges: "Lokale Abgaben",
    package: {
      towel: "Handtuchpaket",
      welcome: "Willkommenspaket",
    },
    reservation_fee: "Buchungskosten",
    camp: {
      basic_rent: "Grundmiete inkl. 2 Personen",
    },
  },
  status: {
    loading_reservation: "Buchung laden",
  },
  title: {
    filters: "Filters",
    lastminutes: "Lastminutes",
    // available_accommodations: '{0} Keine Unterkünfte zur Verfügung|{1} 1 Unterkunft zur Verfügung|[2,Inf] %count% Unterkünfte zur Verfügung',
    available_accommodations: "Unterkünfte zur Verfügung",
    available_on_lakeresort: "auf Lake Resort",
    available_on_resort: "auf Safari Resort",
    available_at_hotel: "auf Safari Hotel",
    payment_method: "Wählen Sie eine Zahlungsmethode",
    payment_method_subtitle: "Wählen Sie eine der Zahlungsmethoden.",
    reservation_number: "Buchungsnummer",
    reservation_title_term_short: "Fast dort",
    reservation_subtitle_term_short:
      "Um Ihre Buchung abzuschließen, müssen Sie nur bezahlen.",
    reservation_title_term_medium: "Fast dort",
    reservation_subtitle_term_medium:
      "Um Ihre Buchung abzuschließen, müssen Sie nur bezahlen.",
    reservation_title_term_long: "Ihre Buchung wurde erfolgreich gesendet",
    reservation_subtitle_term_long:
      "Sie können Ihre Reservierung jetzt direkt online bezahlen.",
    voucher_form: "Zeigen Sie Ihr persönliches Angebot",
    voucher_form_subtitle_1:
      "Haben Sie von uns einen Gutschein oder eine Geschenkkarte erhalten?",
    voucher_form_subtitle_2:
      "Geben Sie es hier ein, um Ihr Angebot einzulösen.",
  },
  error: {
    api: {
      accommodation_not_available:
        "Die ausgewählte Option ist bei dieser Buchung nicht möglich. Bitte überprüfen Sie Ihre Auswahl.",
      unexpected:
        "Diese Buchung ist nicht mehr verfügbar oder für Ihre Reisegruppe nicht möglich.",
    },
    form: {
      preference_not_available:
        "Diese Nummer ist nicht mehr verfügbar. Bitte wählen Sie einen anderen aus.",
    },
    email_invalid: "Ungültige E-Mail-Adresse",
    help: "Brauchen Sie Hilfe? Kontaktieren Sie uns telefonisch unter 088-9000360.",
    newyse: {
      general:
        "Fehler beim Abrufen Verfügbarkeit. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns unter + 31(0)88-9000360",
    },
    customer_service: "Diese Reservierung ist nicht verfügbar",
    save_booking_failure: "Beim Versand der E-Mail ist ein Fehler aufgetreten.",
    unknown_payment_error:
      "Beim Bezahlen der Reservierung ist ein unbekannter Fehler aufgetreten.",
    internal: {
      title: "Sorry, das hat nicht funktioniert!",
      message:
        "Leider hat etwas nicht funktioniert. Versuchen Sie es später noch einmal oder nehmen Sie Kontakt zu unserem Kontaktcenter auf.<br /><br />Wenn Sie Fragen über einen Aufenthalt in unserem Lake Resort, im Safari Resort oder im Safari Hotel haben, können Sie uns anrufen, Tel.: <strong>+31 (0)88-9000360</strong> oder senden Sie uns eine E-Mail an: <strong>Info@libemafunfactory.nl</strong>. Haben Sie noch Fragen zum Eintritt in den Safaripark? Rufen Sie uns einfach an, Tel.: <strong>+31 (0)88-9000321</strong> oder senden Sie eine E-Mail an: <strong>gastenservicesafaripark@beeksebergen.nl</strong>.",
      email: "info@libemafunfactory.nl",
      phone: "+31 (0)88 - 9000360",
      phoneVal: "+310889000360",
    },
  },
  gallery: {
    images: "fotos",
  },
  modals: {
    error: {
      reservation_unavailable: "Ihre Reservierung ist nicht verfügbar",
      meet_requirements:
        "Die Reisegruppe erfüllt nicht die Bedingungen für die Reservierung. Bitte ändern Sie das Reisegruppe.",
      try_again:
        "Während der Buchung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns unter + 31(0)88-9000360",
    },
    info: {
      travel_company: {
        title: "Reisegruppe wird geändert",
        body: "Weil kein Haustier in dieser Unterkunft erlaubt ist, haben wir es aus Ihrem Reisegruppe entfernt.",
      },
    },
  },
  placeholder: {
    title: "Anrede",
    first_name: "Vorname oder Initialen",
    initials: "Initialen",
    middle_name: "Namenszusatz",
    last_name: "Familienname",
    address: "Adresse",
    address_and_number: "Straße und Hausnummer",
    street: "Straße",
    housenumber: "Hausnummer",
    housenumber_suffix: "Zum Beispiel 2H oder Apt-3.",
    zipcode: "Postleitzahl",
    city: "Wohnort",
    country: "Land",
    email: "E-Mail-Adresse",
    phone: "Einschließlich Ländercode, z. B. +316123456789",
    voucher: "Geben Sie Ihren Gutscheincode hier",
  },
  newyse: {
    accommodation: {
      almost_soldout: "Nur wenige verfügbar.",
      availability_resort:
        "Wissen Sie, dass am Mittwoch, Safari Resort auch möglich ist, wenn die Ankunft?",
      book: "Direkt buchen",
      booking_offline_warning:
        "Da der Anreisetag heute ist, ist nur eine telefonische Buchung über +31 (0)88 - 9000 360 möglich",
      more_info: "<span>Weitere </span>Informationen",
      resort_unavailable: "Das Safari Resort öffnet ab April 2018",
      unavailable: "Nicht verfügbar.",
      unavailable2: "Sie das erste mögliche Ankunfstdatum an.",
      check: "Sehen",
      via_more_info: "über mehr Info.",
      no_results: "Es gibt keine Unterkunftstyp ausgewählt.",
    },
    compare: {
      duplicate: "Diese Unterkunft wurde bereits ausgewählt",
      label: "Vergleichen",
      max: "Sie haben die maximale Anzahl der Unterkünfte vergleichen erreicht.",
      start: "Beginnen Sie mit vergleichen",
    },
    last_minute_special: "Last minute",
    no_results:
      "Es wurden keine Ergebnisse gefunden. Bitte passen Sie Ihre Suchkriterien.",
    resort: {
      holidaypark: "Lake Resort Beekse Bergen",
      resort: "Safari Resort Beekse Bergen",
    },
    stay: {
      days: "{1}1 Tag|[2,Inf]%count% Tage",
      nights: "{1}1 Nacht|[2,Inf]%count% Nächte",
    },
  },
  refine: {
    accommodation: "Unterkünfte",
    camping: "Camping",
    park: "Park",
    stay: "Aufenhalt",
    view: "Ansehen",
    result: "Ergebnis",
    results: "Ergebnisse",
    empty_filters: "Filter löschen",
    close: "Schließen",
    apply: "Anwenden",
    modal: {
      title: "Achtung",
      chosen: "Sie haben die sonderwünsche gewählt",
      pets_allowed: "haustiere erlaubt",
      no_pets:
        "Sie haben im Moment kein Haustier in Ihre Reisegruppe enthalten.",
      add_pets:
        "Wenn Sie ein Haustier mitbringen möchten, bitten wir Sie dies zu Ihrer Reisegruppe hinzufügen.",
    },
  },
  routes: {
    anchor: {
      photos: "fotos",
      description: "beschreibung",
      facilities: "einrichtungen",
    },
    accommodations: "/ubernachten/unterkunfte/",
    accommodations_dierenbos: "/de/ferienhauser",
    booking_url: "/de/buchung",
    booking_url_fallback: "/de/buchung-storung-formular",
    compare: "/de/ubernachten/unterkunfte/vergleichen",
    compare_dierenbos: "/de/vergleichen",
    lake_resort: "/lake-resort/",
    home: "/de",
    last_minute: "/de/ubernachten/angebote/last-minute-buchungen",
    newsletter: "/nieuwsbrief",
    payment_request: "/de/buchung/zahlung",
    search_book: "/de/ubernachten/suchen-buchen",
    search_book_dierenbos: "/de/suchen-buchen",
    search: "/de/suchen",
    reminder: "/de/ubernachten/reminder-preise-2026",
    reminder_dierenbos: "/de/reminder-preise-2026",
    reserve: "/de/ubernachten/reservierung-2026",
    reserve_dierenbos: "de/reservieren-2026",
    tickets: "/de/tickets",
  },
  search: {
    placeholder: "Suchen",
    no_results: "Leider haben wir keine Ergebnisse für",
    try_again: "versuchen Sie eine neue Suche",
  },
  sort: {
    best_match: "Am besten passend",
    popularity: "Beliebtheit",
    price_asc: "Preis (aufsteigend)",
    price_desc: "Preis (absteigend)",
  },
  subscribe: {
    caption:
      "Achtung: Sie abonnieren einen Newsletter in niederländischer Sprache",
    newsletter_bb: "Beekse Bergen Newsletter",
    placeholder: "Ihre E-Mail-Adresse",
    sign_up: "Anmeldung",
  },
  ticket: {
    label_sp: "",
    online_discount: "Online mit Rabatt",
    last_minute_discount: "Bis 30 % Rabatt",
    order: "Last-minute",
    subtitle: "Eintritt für Kinder bis 3 Jahre kostenlos",
    title: "Tagesausflug oder Kurztrip?",
    receive: "Ich möchte die",
  },
  travel_company: {
    heading:
      "Wie viele Menschen bringen Sie? Es sollte mindestens 1 Erwachsenen",
    baby: "Kleinkinder bis 3 Jahre",
    child: "Kinder bis 12 Jahre",
    adult: "Erwachsene",
    senior: "Senioren 55+",
    minimum: "mindestens 1 Erwachsener",
    pet: "Haustier",
  },
  weather: {
    today: "Heute",
    tomorrow: "Morgen",
    closed: "Geschlossen",
  },
  kind: {
    camping: "Campingurlaub",
  },
  reservation_checklist: {
    prices: "Immer <strong>All-In-Preise</strong>, keine bösen Überraschungen",
    confirmation:
      "Sie erhalten umgehend eine <strong> vorläufige Bestätigung </ strong>",
    extras:
      "Etwas vergessen? Später können Sie immer <strong> Extras </ strong> buchen.",
    cancellation:
      "Kostenlose Stornierung innerhalb von 24 Stunden nach der Buchung",
    prices_dierenbos:
      "Immer <strong>All-In-Preise</strong>, keine bösen Überraschungen",
    size_dierenbos: "2400 m² Spielplätze",
    pond_dierenbos: "Natürlicher Schwimmteich",
  },
  cancelReservation: {
    title: "Zahlung",
    subtitle:
      "Ihre Buchung wird nach der Zahlung abgeschlossen. Sie sind jetzt im Begriff, diese Buchung abzubrechen. Möchten Sie diesen Vorgang fortsetzen?",
    reservationNumber: "Buchungsnummer",
    cancelPolicy: {
      text1: "Hier finden Sie die ",
      text2: "Stornierungsbedingungen",
      text3: ".",
    },
    actions: {
      continuePayment: "Zahlungsvorgang fortsetzen",
      continuePaymentHelper:
        "Sie bleiben auf der Zahlungsseite und können hier die Zahlung abschließen.",
      exitReservation: "Später bezahlen",
      exitReservationHelper:
        "Sie kehren zur Homepage zurück. Die Buchung bleibt stehen und wir senden Ihnen per E-Mail Anweisungen, wie Sie den Zahlungsvorgang später abschließen können.",
      cancelReservation: "Zum vorigen Schritt zurückkehren",
      cancelReservationHelper:
        "Ihre heutige Buchung wird storniert. Sie kehren zum vorigen Schritt zurück. Dort können Sie Ihre Buchungsdaten prüfen, ändern und neu bestätigen.",
    },
    errors: {
      cancelBookingFailure:
        "Es ist (vorläufig) nicht möglich, mit Ihren Einstellungen eine Buchung vorzunehmen. Versuchen Sie zunächst, die Seite zu aktualisieren. Können wir Ihnen helfen? Gerne! Sie erreichen uns telefonisch unter der Nummer +31 88 9000360!",
    },
  },
};

export default de;
